import { grays, isIterableArray } from './utils';
import React, { useContext, useState } from 'react';
import { countries } from '../data/countries';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AppContext from '../context/Context';

const SelectCountry = ({ active, onSelected }) => {
  const { t, i18n } = useTranslation();
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [country, setCountry] = useState(i18n.language.toUpperCase());
  const [value, setValue] = useState(active);
  const options = [];
  const handleSelect = value => {
    setValue(value);
    setCountry(value);
    onSelected(value);
  };

  const optionsFill = () => {
    {
      isIterableArray(countries) &&
        countries.map((country, index) => {
          options.push({ value: country.name, label: t(country.name) });
        });
    }
  };
  optionsFill();

  const customStyles = {
    singleValue: (provided, state) => {
      const color = isDark ? grays['300'] : grays['900'];
      return { ...provided, color };
    }
  };

  return (
    <Select
      styles={customStyles}
      closeMenuOnSelect={true}
      options={options}
      placeholder={t('Country')}
      classNamePrefix="react-select"
      value={value}
      onChange={handleSelect}
    />
  );
};

SelectCountry.propTypes = {
  onSelected: PropTypes.func,
  active: PropTypes.object
};

export default SelectCountry;
